import React from "react";
import styles from "./Button.module.css";

type PropsType = {
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  gameMode: boolean;
}

const labels = [
  "Essaye encore",
  "VOOOOOOP",
  "CIAOOO",
  "Viens me chercher",
  "Hop laaaa",
  "Nul Germain",
  "Haha nullos"
];

const Button = ({ label, onClick, gameMode }: PropsType) => {
  const [active, setActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    const button = document.querySelector(`.${styles.Button}`);
    if (!button) return;

    button.addEventListener("touchstart", () => setActive(true), { passive: true });
    button.addEventListener("mousedown", () => setActive(true));
    button.addEventListener("touchend", () => setActive(false));
    button.addEventListener("mouseup", () => setActive(false));
  }, []);

  React.useEffect(() => {
    if (gameMode === false) return;
    const button = document.querySelector(`.${styles.Button}`) as HTMLElement;
    if (!button) return;
    button.style.position = "fixed";
    button.style.animationDuration = "2s";
    button.style.transition = "all .1s linear";

    const buttonLogic = () => {
      button.textContent = labels[Math.floor(Math.random() * (labels.length))];
      const size = button.getBoundingClientRect();
      button.style.top = `${Math.floor(Math.random() * (window.innerHeight - size.height))}px`;
      button.style.left = `${Math.floor(Math.random() * (window.innerWidth - size.width))}px`;
    };

    button.addEventListener("mouseover", buttonLogic);
    button.addEventListener("touchstart", buttonLogic);
  }, [gameMode]);

  return <button className={`${styles.Button} ${active && styles.Active}`} disabled={gameMode} onClick={onClick}>{label}</button>;
};

export default Button;
