import logo from "./assets/images/nohomo_logo.svg";

import styles from "./App.module.css";
import React from "react";
import Button from "./Components/Button";

const rules = [
  "C'est ok si les coudes ne touchent pas",
  "C'est ok si les couilles ne touchent pas",
  "C'est ok si il n'y a pas de sentiments",
  "C'est ok si les regards ne se croisent pas",
  "C'est ok si c'est pour tester",
  "C'est ok si c'est pas fait exprès",
  "C'est ok si c'est pour dépanne",
  "C'est ok si tu dis « no homo »"
];

function App() {
  const [clickHistory, setClickHistory] = React.useState<Date[]>([]);
  const [isSocketUp, setIsSocketUp] = React.useState<boolean>(false);
  const [counter, setCounter] = React.useState<number | null>(null);
  const [gameMode, setGameMode] = React.useState<boolean>(false);
  const [sus, setSus] = React.useState<boolean>(false);
  const [ws, setWs] = React.useState<WebSocket | null>(null);

  const formatNumber = (num: number): string =>
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

  const clickHandler = () => {
    if (!ws) return;
    setClickHistory((c) => [...c, new Date()]);

    if (gameMode) return;
    if(ws.OPEN && isSocketUp) ws.send("increment");
  };

  // ?: On load
  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL || "http://localhost:9900"}/counter`)
      .then(response => response.json())
      .then(data => setCounter(data.counter))
      .catch(error => console.error(error));
    setWs(new WebSocket(`${process.env.REACT_APP_WS_URL || "ws://localhost:9900"}/ws`));

    console.log("C'est homo d'ouvrir la console 👀");
  }, []);

  // ?: WebSocket
  React.useEffect(() => {
    if (!ws) return;

    ws.onerror = (ev) => {
      console.error("WebSocket error", ev);
      ws.close();
      setTimeout(() => {
        setWs(new WebSocket(`${process.env.REACT_APP_WS_URL || "ws://localhost:9900"}/ws`));
      }, 1000);
    };

    ws.onclose = (ev) => {
      console.log("WebSocket connection closed", ev);
      setIsSocketUp(false);
      // Retry connection after 1 second
      setTimeout(() => {
        setWs(new WebSocket(`${process.env.REACT_APP_WS_URL || "ws://localhost:9900"}/ws`));
      }, 1000);
    };

    ws.onopen = (ev) => {
      console.log("WebSocket connection opened", ev);
      setIsSocketUp(true);
      // You can add any connection initialization code here
    };

    ws.onmessage = (msg) => {
      if (ws.OPEN && msg.data !== "increment") {
        setCounter(parseInt(msg.data, 10));
      }
    };
  }, [ws]);

  // ?: Click History process
  React.useEffect(() => {
    // ?: Show the DAWG
    if ([4, 15, 35].find(n => n === clickHistory.length)) {
      setSus(true);
      setTimeout(() => setSus(false), 10100);
    }

    const arr = clickHistory.slice(-5);
    if (arr.length < 5) return;

    if (((arr[arr.length - 1]?.getTime() || 0) - (arr[0]?.getTime() || 0)) < 1000)
      setGameMode(true);
  }, [clickHistory]);

  return (
    <div className={styles.App}>
      <div className={styles.Background}>
        <div className={`${styles.Band} ${styles.Red}`} />
        <div className={`${styles.Band} ${styles.Orange}`} />
        <div className={`${styles.Band} ${styles.Yellow}`} />
        <div className={`${styles.Band} ${styles.Green}`} />
        <div className={`${styles.Band} ${styles.Blue}`} />
        <div className={`${styles.Band} ${styles.Purple}`} />
      </div>
      <main className={styles.Main}>
        <div className={styles.Dawg}>
          <picture style={{ display: sus ? "block" : "none" }}>
            <source srcSet="dawg.webp" className={sus ? `${styles.Active}` : ""} type="image/webp" />
            <source srcSet="dawg.png" className={sus ? `${styles.Active}` : ""} type="image/png" />
            <img src="dawg.png" className={sus ? `${styles.Active}` : ""} alt="dawg" />
          </picture>
        </div>
        <header className={styles.Header}>
          <img src={logo} className={styles.Logo} alt="main site logo" />
        </header>
        <section className={styles.Counter}>
          <div className={styles.Value}>{counter ? formatNumber(counter) : "-"}</div>
          <div className={styles.Descriptor}>quiproquos évités</div>
        </section>
        <section style={{ width: "100%", display: "flex", justifyContent: "center", height: 65, marginBottom: "4.5em" }}>
          <Button label="No Homo !" onClick={clickHandler} gameMode={gameMode} />
        </section>
        <section className={styles.Rules}>
          <h1 className={styles.Title}>Les règles</h1>
          <ul className={styles.List}>
            {rules.map((rule, index) => (
              <li className={styles.Item} key={index}>
                <div className={styles.Check}>✓</div>
                <p className={styles.Text}>{rule}</p>
              </li>
            ))}
          </ul>
        </section>
      </main>
      <footer className={styles.Footer}>
        <section  className={styles.Contact}>
          <p>
            Encore un doute ? Contactez nous : <a href="mailto:g1doute@nohomo.fr">g1doute@nohomo.fr</a>
          </p>
          <p>
            Vous souhaitez être certain de votre sexualité ?<br /><a href="https://gaytest.nohomo.fr" target="_blank" rel="noreferrer">Faites le test</a>
          </p>
        </section>
      </footer>
    </div>
  );
}

export default App;
